import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { BehaviorSubject, Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-person-section',
  templateUrl: './person-section.component.html',
  styleUrls: ['./person-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PersonSectionComponent implements OnChanges {

  @Input()
  public readonly form: FormGroup = new FormGroup({});

  private _editMode = new BehaviorSubject(false);
  private _editableSection$: Observable<boolean> = new Observable();

  public get firstNameControl(): FormControl {
    return (this.form.get('first_name') as FormArray)?.at(0) as FormControl;
  }

  public get lastNameControl(): FormControl {
    return (this.form.get('last_name') as FormArray)?.at(0) as FormControl;
  }

  public get editMode$(): Observable<boolean> {
    return this._editMode;
  }

  public get editableSection$(): Observable<boolean> {
    return this._editableSection$;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.form && changes.form.currentValue !== changes.form.previousValue) {
      this._init();
    }
  }

  public enterEditMode(): void {
    this._editMode.next(true);
  }

  private _init(): void {
    this._editableSection$ = this.form.statusChanges
      .pipe(
        startWith(this.form.disabled),
        map((_) => this.form.disabled),
        map((v) => !v),
      );
  }
}
