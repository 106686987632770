import {
  Directive,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, map } from 'rxjs/operators';

import { provideFormControlPath } from '../../providers/path.provider';
import { IExternalValidationError } from '../../interfaces/external-validation-error.interface';
import { ScrollToError } from '../../services/scroll-to-error.service';

import { BaseControlDirective } from './base-control.directive';


@Directive({
  // eslint-disable-next-line
    selector: '[formControlName]',
  providers: [
    provideFormControlPath(),
  ],
  standalone: false,
})
export class FormControlNameDirective extends BaseControlDirective implements OnInit {

  @Input()
  public set formControlName(value: string | number) {
    this._setControlName(String(value));
  }

  private _ngControl: NgControl = inject(NgControl, { self: true, optional: true });
  private _scrollToError = inject(ScrollToError);

  public ngOnInit() {
    super.ngOnInit();

    this._listenControl();
  }

  protected get _control(): AbstractControl {
    return this._ngControl.control;
  }

  protected _initControl(): void {
    this._setControlName(this._ngControl.name);
  }

  protected _listenControl(): void {
    this._cForm.errors$
      .pipe(
        map((errors: IExternalValidationError[]) => {
          return errors
            .find((error: IExternalValidationError) => {
              return error.path === this._controlPath.path;
            });
        }),
        filter((error: IExternalValidationError) => !!error),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((err: IExternalValidationError ) => {
        this._control.setErrors({
          [err.code]: true,
        });
      });
  }
}
