import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-top-bar-default',
  templateUrl: './top-bar.component.html',
  imports: [
    NgIf,
    TranslateModule,
  ],
})
export class TopBarDefaultComponent {

  @Input()
  public firstLine: string;

  @Input()
  public secondLine: string;

  constructor() {}
}
