import {
  Directive,
  inject,
} from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

import { provideFormControlPath } from '../../providers/path.provider';

import { BaseControlDirective } from './base-control.directive';


@Directive({
  // eslint-disable-next-line
    selector: '[formArrayName]',
  providers: [
    provideFormControlPath(),
  ],
  standalone: false,
})
export class FormArrayNameDirective extends BaseControlDirective {

  private _controlContainer: ControlContainer
    = inject(ControlContainer, { self: true, optional: true });

  protected get _control(): AbstractControl {
    return this._controlContainer.control;
  }

  protected _initControl(): void {
    this._setControlName(this._controlContainer.name);
  }

}
