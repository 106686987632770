import { Directive, inject, OnInit, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IExternalValidationError } from '../interfaces/external-validation-error.interface';
import { IRemoteValidationError } from '../interfaces/remote-validation-error.interface';
import { processRemoteValidationErrors } from '../helpers/process-remote-validation-errors';
import { ScrollToError } from '../services/scroll-to-error.service';


@Directive({
  // eslint-disable-next-line
    selector: '[cForm]',
  providers: [
    ScrollToError,
  ],
  standalone: false,
})
export class FormDirective implements OnInit {

  private readonly _scrollToError = inject(ScrollToError);

  // eslint-disable-next-line
  @Output()
  public generalSectionError = this._scrollToError.generalSectionError$;

  private readonly _form: FormGroupDirective = inject(FormGroupDirective);
  private readonly _externalErrors$ = new BehaviorSubject<IExternalValidationError[]>([]);

  public get errors$(): Observable<IExternalValidationError[]> {
    return this._externalErrors$.asObservable();
  }

  public get errors(): IExternalValidationError[] {
    return this._externalErrors$.value;
  }

  public get form(): FormGroupDirective {
    return this._form;
  }

  public ngOnInit(): void {
    this._listenSubmit();
    this._scrollToError.init(this._form);
  }

  public setRemoteErrors(errors: IRemoteValidationError[]): void {
    this._externalErrors$.next(processRemoteValidationErrors(errors));
  }

  private _listenSubmit(): void {
    this._form
      .ngSubmit
      .pipe(
        tap((event) => {
          event?.preventDefault();
        }),
      )
      .subscribe();
  }
}
