import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ErrorFooterComponent } from '../../../libs/error-footer';


@Component({
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss'],
  imports: [
    TranslateModule,
    ErrorFooterComponent,
  ],
})
export class ExpiredComponent {}
