import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { IconDirective } from '@common/directives';


@Component({
  selector: 'app-collapsable-section',
  templateUrl: './collapsable-section.component.html',
  styleUrls: ['./collapsable-section.component.scss'],
  imports: [
    NgIf,
    NgClass,
    IconDirective,
  ],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*',
      })),
      state('closed', style({
        height: '0px',
      })),
      transition('open => closed', [
        animate('0.5s'),
      ]),
      transition('closed => open', [
        animate('0.3s'),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsableSectionComponent {

  @Input()
  public collapsable = true;

  @Input({ required: true })
  public title: string;

  @Input()
  public icon: string;

  private _collapsed = false;

  public get collapsed(): boolean {
    return this._collapsed;
  }

  public toggleCollapse(): void {
    if (!this.collapsable) {
      return;
    }

    this._collapsed = !this._collapsed;
  }
}
