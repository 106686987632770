import { Component, OnDestroy } from '@angular/core';

import { PageType } from '../../../../libs/common/enums/page.type';
import { PersistanceService } from '../../../core/services/persistance.service';


@Component({
  selector: 'app-simple-done',
  templateUrl: './simple-done.component.html',
  styleUrls: ['./simple-done.component.scss'],
  standalone: false,
})
export class SimpleDoneComponent implements OnDestroy {

  public readonly pageType = PageType.SimpleDone;

  constructor(
    private _persistanceService: PersistanceService,
  ) {}

  public ngOnDestroy(): void {
    this._persistanceService.setItem('latestPageType', String(this.pageType));
  }

}
