import { Directive, inject } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

import { provideFormControlPath } from '../../providers/path.provider';
import { getControlContainerName } from '../../helpers/get-control-container-name';

import { BaseControlDirective } from './base-control.directive';


@Directive({
  // eslint-disable-next-line
    selector: '[formGroupName]',
  providers: [
    provideFormControlPath(),
  ],
  standalone: false,
})
export class FormGroupNameDirective extends BaseControlDirective {

  private _controlContainer: ControlContainer
    = inject(ControlContainer, { self: true, optional: true });

  protected get _control(): AbstractControl {
    return this._controlContainer.control;
  }

  protected _initControl(): void {
    const name = getControlContainerName(this._controlContainer);

    this._setControlName(name);
  }

}
