import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { PageType } from '../../../../libs/common/enums/page.type';
import { StoreService } from '../../../core/services/store.service';
import { SetupInterface } from '../../../../libs/common/interfaces/setup.interface';
import { FeatureType } from '../../../../libs/common/enums/feature.type';
import { Side } from '../../../../libs/common/classes/side';
import { AppNavService } from '../../../core/services/app-nav.service';
import { ApiService } from '../../../core/services/api.service';
import { PersistanceService } from '../../../core/services/persistance.service';
import { BirthdayVerificationComponent } from '../../../../libs/code-input/birthday-verification';


@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.scss'],
  standalone: false,
})
export class BirthdayComponent implements OnInit, AfterViewInit, OnDestroy {

  /* ----------------------------------------
   * Data
   * -------------------------------------- */
  setup: SetupInterface;
  receiver: Side;
  pageType = PageType.DOBAuthentication;
  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  featureType = FeatureType;
  /* ----------------------------------------
   * View
   * -------------------------------------- */
  loaded = false;
  showLoader = true;
  showInputError = false;
  showVerificationError = false;
  showTooManyRequestError = false;
  enableSubmitButton = false;
  attempts = 0;

  public birthdayModel: [string?, string?, string?] = [];

  phoneNumber = '';
  link = '';
  anchor = '';
  tooManyRequestLink = '';

  @Input() tanRecipient = '';

  @ViewChild(BirthdayVerificationComponent, { static: false })
  private _codeInput: BirthdayVerificationComponent;

  constructor(
    public storeService: StoreService,
    private appNavService: AppNavService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private translate: TranslateService,
    private persistanceService: PersistanceService,
  ) { }

  ngOnInit(): void {
    this.appNavService.currentPageType = this.pageType;
    this.appNavService.currentPageIndex = this.storeService?.setup?.sequence.indexOf(this.pageType) || 0;
    this.setup = this.storeService.setup;

    this.phoneNumber = this.translate.instant('sender.phoneNumber');
    this.link = this.translate.instant('sender.link');
    this.anchor = this.translate.instant('sender.anchor');
    this.tooManyRequestLink = this.translate.instant('birthday.tooManyRequestLink');
  }

  ngOnDestroy(): void {
    this.persistanceService.setItem('latestPageType', String(this.pageType));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showLoader = false;
      this.loaded = true;
    }, 3000);
  }

  changed() {
    const day = this.birthdayModel[0];
    const month = this.birthdayModel[1];
    const year = this.birthdayModel[2];

    this.enableSubmitButton = !!day && !!month && !!year && year.length === 4;

    if (this.enableSubmitButton) {
      if (this.attempts === 0) {
        this.onSubmit();
        this.attempts++;
      }
    }
  }

  keyup(e) {
    if (this.enableSubmitButton && this.attempts > 0 && e.key === 'Enter') {

      this.attempts++;
      this.onSubmit();
    }
  }

  onSubmit() {
    const numericDay = Number(this.birthdayModel[0]);
    const numericMonth = Number(this.birthdayModel[1]);
    const numericYear = Number(this.birthdayModel[2]);

    const dateOfBirth = `${numericDay < 10 ? `0${numericDay}` : numericDay
    }.${numericMonth < 10 ? `0${numericMonth}` : numericMonth
    }.${numericYear
    }`;

    if (
      isNaN(numericDay) ||
      isNaN(numericMonth) ||
      isNaN(numericYear) ||
      numericDay < 1 ||
      numericDay > 31 ||
      numericMonth < 1 ||
      numericMonth > 12
    ) {
      this.showError('inputError');
      this._codeInput.focusFirst();

      return;
    }

    this.showLoader = true;
    this.storeService.setAuthenticationCode(null);
    this.hideErrors();

    this.apiService.checkVerificationCode(
      this.storeService.token,
      dateOfBirth,
      this.activatedRoute.snapshot.queryParams.source,
    ).subscribe(
      () => {
        this.storeService.setAuthenticationCode(dateOfBirth);

        const requestedPage = Number(this.persistanceService.getItem('requestedPage'));
        if (requestedPage) {
          this.appNavService.goToPageType(
            requestedPage,
            {
              code: dateOfBirth,
              source: this.activatedRoute.snapshot.queryParams.source,
            },
          );
        } else {
          this.appNavService.goToNext(
            this.storeService.setup.sequence,
            this.pageType,
            {
              code: dateOfBirth,
              source: this.activatedRoute.snapshot.queryParams.source,
            },
          );
        }

        this.showLoader = false;
      },
      (response) => {
        if (!response || response instanceof HttpErrorResponse) {
          if (response.status && response.status === 429) {
            this.showError('tooManyRequestsError');
          } else {
            this.showError('inputError');
          }
        } else {
          this.showError('verificationError');
        }

        this.showLoader = false;

        // must be in setTimeout here because of async *ngIf for showLoader
        setTimeout(() => {
          this._codeInput.focusFirst();
        });
      },
    );
  }

  showError(error: 'tooManyRequestsError' | 'inputError' | 'verificationError') {
    this.hideErrors();
    this.storeService.setBlocked(true);

    switch (error) {
      case 'tooManyRequestsError': {
        this.showTooManyRequestError = true;
      } break;

      case 'inputError': {
        this.showInputError = true;
      } break;

      case 'verificationError': {
        this.showVerificationError = true;
      } break;
    }


  }

  hideErrors(): void {
    this.storeService.setBlocked(false);
    this.showTooManyRequestError = false;
    this.showInputError = false;
    this.showVerificationError = false;
  }

}
