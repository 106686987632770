import { Component, Input, OnInit } from '@angular/core';

import { PageType } from '../../../libs/common/enums/page.type';
import { SetupInterface } from '../../../libs/common/interfaces/setup.interface';
import { StoreService } from '../../core/services/store.service';
import { FeatureType } from '../../../libs/common/enums/feature.type';


@Component({
  selector: 'app-sender-message-element',
  templateUrl: './sender-message-element.component.html',
  styleUrls: ['./sender-message-element.component.scss'],
  standalone: false,
})
export class SenderMessageElementComponent implements OnInit {


  @Input() pageType: PageType;

  imageUrl: string;
  setup: SetupInterface;
  featureType = FeatureType;

  constructor(private storeService: StoreService) {
    this.imageUrl = this.storeService?.sender?.person?.imageUrl || '';
  }

  ngOnInit(): void {
    this.setup = this.storeService.setup;
  }

}
