import { Component, Input, OnInit } from '@angular/core';
import { AsyncPipe, NgIf, NgSwitchCase } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { filterAdvertisementConsents } from '@common/helpers/consents/filter-advertisement-consents';
import {
  filterAppointmentConsents,
} from '@common/helpers/consents/provinzial/filter-appointment-consents';
import { syncConsentsInStore } from '@common/helpers/consents/sync-consents-in-store';
import { SafeHtml } from '@common/pipes/safe.pipe';

import { merge, Observable, of, zip } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { StoreService } from '../../app/core/services/store.service';
import { PageType } from '../common/enums/page.type';
import { SetupInterface } from '../common/interfaces/setup.interface';
import { FeatureType } from '../common/enums/feature.type';
import { Consent } from '../common/classes/consent';
import { ConsentType } from '../common/enums/consent.type';
import { LegalContentInterface } from '../common/interfaces/legal-consent.interface';
import { CollapsableSectionComponent } from '../collapsable-section';
import { ConsentsListComponent } from '../consents-list/consents-list.component';
import { LegalElementComponent } from '../legal-element/legal-element.component';


@Component({
  selector: 'app-consents-section',
  templateUrl: './consents-section.component.html',
  styleUrls: ['./consents-section.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
      })),
      state('closed', style({
        height: '0px',
      })),
      transition('open => closed', [
        animate('0.5s'),
      ]),
      transition('closed => open', [
        animate('0.3s'),
      ]),
    ]),
  ],
  imports: [
    AsyncPipe,
    CollapsableSectionComponent,
    NgIf,
    ConsentsListComponent,
    LegalElementComponent,
    TranslateModule,
    SafeHtml,
  ],
})

export class ConsentsSectionComponent implements OnInit {

  @Input()
  public pageType: PageType;

  public setup: SetupInterface;
  public values: [] = [];
  public isActive: boolean;

  /* ----------------------------------------
   * Dicts
   * -------------------------------------- */
  public featureType = FeatureType;
  public consentType = ConsentType;

  public advertisementConsents: Consent[] = [];
  public appointmentConsents: Consent[] = [];
  public showProvinzialConsents = false;

  public legalVisible$ = this._legalContent$.pipe(
    map((legal: LegalContentInterface | null) => {
      return !!legal?.subject
        || (!!legal?.description && legal?.description !== '<p><br></p>');
    }),
  );

  public legalFinePrint$: Observable<string> = this._legalContent$.pipe(
    map((legal: LegalContentInterface | null) => {
      if (!legal?.additional || legal?.additional === '<p><br></p>') {
        return '';
      }

      return legal.additional;
    }),
  );

  public get preferencesSectionVisible$(): Observable<boolean> {
    return this._preferencesSectionVisible$;
  }

  private _preferencesSectionVisible$ = zip(
    of(this.storeService.setup.showConsents),
    of(this.storeService.preferences),
    this.legalVisible$,
    this.legalFinePrint$,
  ).pipe(
    map(([
      showConsents,
      preferences,
      legalVisible,
      finePrint,
    ]: [boolean, Consent[], boolean, string
      ]) => {
      const features = this.setup.features[this.pageType];

      // Check if Legal html provided
      const legalSectionVisible = features[this.featureType.LegalHtml]
        && !!this.storeService.customLegal
        && !!legalVisible;

      // Check if any consent (default or ReO) provided
      const hasDefaultConent = features[this.featureType.ShowDefaultConsents]
        && preferences.some((consent) => consent.type === this.consentType.Default);
      const hasReoConsent = features[this.featureType.ShowReoConsents]
        && preferences.some((consent) => consent.type === this.consentType.Reo);
      const consentsVisible = hasReoConsent || hasDefaultConent;

      return showConsents && (consentsVisible || legalSectionVisible || !!finePrint);
    }),
    shareReplay(),
  );

  constructor(
    public storeService: StoreService,
    private _translateService: TranslateService,
  ) { }

  private get _legalContent$(): Observable<LegalContentInterface | null> {
    return merge(
      of(this._translateService.getDefaultLang()),
      this._translateService.onLangChange.pipe(map((event: LangChangeEvent) => event.lang)),
    ).pipe(
      map((lang: string) => {
        return this.storeService.customLegal
          ? (this.storeService.customLegal[lang] || this.storeService.customLegal.de)
          : null;
      }),
    );
  }

  public ngOnInit(): void {
    this.setup = this.storeService.setup;
    this.isActive = this.setup.features[this.pageType][this.featureType.PreferenceOpen];
    this._initConsents();
  }

  public consentsChanged(consents: Consent[]): void {
    syncConsentsInStore(this.storeService.preferences, consents);
  }

  private _initConsents(): void {
    this._initAdvertisementConsents();

    this._initAppointmentConsents();
    this._updateConsentGivenFlag();
  }

  private _initAdvertisementConsents(): void {
    this.advertisementConsents = filterAdvertisementConsents(
      this.storeService.preferences,
      {
        showDefaultConsents: this.setup.features[this.pageType][FeatureType.ShowDefaultConsents],
        showReoConsents: this.setup.features[this.pageType][FeatureType.ShowReoConsents],
      },
    );
  }

  private _initAppointmentConsents(): void {
    this.appointmentConsents = filterAppointmentConsents(this.storeService.preferences);
  }

  private _updateConsentGivenFlag(): void {
    this.showProvinzialConsents = this.appointmentConsents.length > 0
      && !this.appointmentConsents.every((consent) => consent.value);
  }
}
