import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IFormField } from '@c-fields/common';

import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { FormDirective } from '@c-form';

import { ERROR_MESSAGES } from '../../../form-field/components/form-field/error-messages.const';


@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: [
    './form-group.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FormGroupComponent implements OnInit, OnDestroy {

  @Input()
  public field: IFormField;

  @Input()
  public form: FormGroup;

  private _form: FormDirective = inject(FormDirective);
  private _destroy$ = new Subject<void>();

  // eslint-disable-next-line
  public sectionError$ = this._form.errors$
    .pipe(
      filter((errors) => {
        return !!errors.find((error) => {
          return error.path === `${this.field.section}.${this.field.type}`;
        });
      }),
      map((e) => e[0]),
      map((e) => {
        return ERROR_MESSAGES[e.code] || e.message;
      }),
    );

  public ngOnInit(): void {
    this._listenArrayChanges();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _listenArrayChanges(): void {
    this.field.formArray.valueChanges
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.field.addEmptyFormControlIfNeeded();
      });
  }
}
