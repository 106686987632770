import { Component } from '@angular/core';

import { TopBarDefaultComponent } from '../../default/top-bar/top-bar.component';
import { arrayIncludesSubset } from '../../../../libs/common/functions/array-includes';
import { StoreService } from '../../../../app/core/services/store.service';


@Component({
  selector: 'app-top-bar-telekom',
  templateUrl: './top-bar.component.html',
  imports: [
    TopBarDefaultComponent,
  ],
})
export class TelekomTopBarComponent {

  public firstLine: string;
  public secondLine: string;

  constructor(
    private _storeService: StoreService,
  ) {
    if (arrayIncludesSubset(this._storeService.receiver.tags, 'mobilfunk')) {
      this.firstLine = 'Mobilfunk';
    } else if (arrayIncludesSubset(this._storeService.receiver.tags, 'festnetz')) {
      this.firstLine = 'Festnetz';
    }

    this.secondLine = this._storeService.contractNumber || null;
  }
}
