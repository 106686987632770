import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { animate, sequence, state, style, transition, trigger } from '@angular/animations';

import { environment } from '../../../environments/environment';
import { LangService } from '../../core/modules/translation';
import { StoreService } from '../../core/services/store.service';
import { PageType } from '../../../libs/common/enums/page.type';
import { FeatureType } from '../../../libs/common/enums/feature.type';

import { InfoFileLoaderService, InfoLoaderState } from './info-file-loader.service';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  providers: [
    InfoFileLoaderService,
  ],// eslint-disable-next-line
  animations: [
    trigger('show', [
      state('visible', style({
        opacity: 1,
        display: 'flex',
      })),
      state('hidden', style({
        opacity: 0,
        display: 'none',
      })),
      transition('visible => hidden', [
        sequence([
          style({ opacity: 1 }),
          animate('300ms', style({ opacity: 0 })),
          style({ display: 'flex' }),
          animate('0ms', style({ display: 'none' })),
        ]),
      ]),
      transition('hidden => visible', [
        sequence([
          style({ display: 'none' }),
          animate('0ms', style({ display: 'flex' })),
          style({ opacity: 0 }),
          animate('300ms', style({ opacity: 1 })),
        ]),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InfoComponent implements OnInit {

  tabs: { isActive: boolean, titleCode: string, fileName: string, cyTag: string }[] = [
    { isActive: true, titleCode: 'info.buttons.imp', fileName: 'imp.md', cyTag: 'impressum' },
    { isActive: false, titleCode: 'info.buttons.data', fileName: 'data.md', cyTag: 'data' },
  ];

  textsFolder: string = environment.textsFolder;

  pageType = PageType;
  featureType = FeatureType;

  popupVisible = false;

  infoLoaderState = InfoLoaderState;

  constructor(
    public langService: LangService,
    public storeService: StoreService,
    public fileLoader: InfoFileLoaderService,
  ) {}

  // constructor(private translateService: TranslateService, private cdRef: ChangeDetectorRef) {
  //   this.tabs[0].titleCode = new TranslatePipe(this.translateService, this.cdRef).transform('info.buttons.impr');
  //   this.tabs[1].titleCode = new TranslatePipe(this.translateService, this.cdRef).transform('info.buttons.impr');
  //   this.tabs[2].titleCode = new TranslatePipe(this.translateService, this.cdRef).transform('info.buttons.impr');
  // }

  ngOnInit() {
    // const impressumUrl = '/assets/texts/impressum.txt';
    // this.get(impressumUrl);
    // this.get(impressumUrl, {responseType: 'text'});
  }

  errorHandler($event: any) {
    console.error($event);
  }

  @HostListener('document:keydown.escape')
  public escapePressed(): void {
    this.popupVisible = false;
  }

  setActive(tab: { isActive: boolean; titleCode: string }) {
    this.tabs.forEach((t) => t.isActive = false);
    tab.isActive = true;

    this._loadMarkdown();
  }

  showPopup() {
    this.popupVisible = !this.popupVisible;

    this._loadMarkdown();
  }

  onPopupContainerClick(event) {
    if (event.target.id === 'popup-container') {
      this.popupVisible = false;
    }
  }

  openExternalInfo() {
    window.open('https://www.telekom.de/impressum', '_blank');
  }

  private _loadMarkdown(): void {
    const activeTab = this.tabs.find((tab) => tab.isActive);

    if (activeTab) {
      const path = `${this.textsFolder + this.langService.current  }/${activeTab.fileName}`;

      this.fileLoader.load(path);
    }
  }
}
