import {
  Component,
  forwardRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { BaseCodeInputComponent } from '../base/base-code-input.component';
import { ICodeInputConfig } from '../base';


@Component({
  selector: 'app-postcode-verification',
  templateUrl: './postcode-verification.component.html',
  styleUrls: [
    '../base/base-code-input.component.scss',
    './postcode-verification.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PostcodeVerificationComponent),
      multi: true,
    },
  ],
  imports: [
    FormsModule,
    NgForOf,
    TranslateModule,
  ],
})
export class PostcodeVerificationComponent extends BaseCodeInputComponent
  implements OnChanges, ControlValueAccessor {

  public items: ICodeInputConfig[] = [
    {
      placeholder: '0',
      maxLength: 1,
      name: 'postcode-1',
      regex: /^[a-zA-Z0-9]$/,
    },
    {
      placeholder: '0',
      maxLength: 1,
      name: 'postcode-2',
      regex: /^[a-zA-Z0-9]$/,
    },
    {
      placeholder: '0',
      maxLength: 1,
      name: 'postcode-3',
      regex: /^[a-zA-Z0-9]$/,
    },
    {
      placeholder: '0',
      maxLength: 1,
      name: 'postcode-4',
      regex: /^[a-zA-Z0-9]$/,
    },
    {
      placeholder: '0',
      maxLength: 1,
      name: 'postcode-5',
      regex: /^[a-zA-Z0-9]$/,
    },
  ];

  private _model: string[] = [];
  // eslint-disable-next-line
  private _onChange: (v) => {};
  // eslint-disable-next-line
  private _onTouched: () => {};

  public get model() {
    return this._model;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.inps?.currentValue !== changes.inps?.previousValue) {
      const emptyArr = new Array(this.items.length);
      emptyArr.fill(null);

      this._model = emptyArr;
    }
  }

  public writeValue(value: string[]): void {
    if (Array.isArray(value)) {
      this._model = [...value];
    }
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public changed(): void {
    this._onChange(this.model);
  }

  public onInput(e: any, index: number) {
    super.onInput(e, index);

    if (typeof this.model[index] === 'string') {
      this.model[index] = this.model[index].toUpperCase();
    }
  }
}
