import { Directive } from '@angular/core';


@Directive({
  // eslint-disable-next-line
    selector: 'field-label',
  standalone: false,
})
export class FieldLabelDirective {

}
