import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-topbar',
  templateUrl: './app-topbar.component.html',
  styleUrls: ['./app-topbar.component.scss'],
  standalone: false,
})
export class AppTopBarComponent {
  @Input() lineOne: string;
  @Input() lineTwo: string;

  constructor() {}
}
