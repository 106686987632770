import { Directive, inject } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

import { Observable } from 'rxjs';

import { FieldControl } from './field-control.directive';


@Directive({
  // eslint-disable-next-line
    selector: '[fieldInput]',
  providers: [
    {
      provide: FieldControl,
      useExisting: FieldInputDirective,
    },
  ],
  standalone: false,
})
export class FieldInputDirective implements FieldControl {

  private _ngControl = inject(NgControl, { self: true });

  public get required(): boolean {
    return this._ngControl?.control?.hasValidator(Validators.required) ?? false;
  }

  public get disabled(): boolean {
    return this._ngControl?.disabled;
  }

  public get statusChanges(): Observable<any> | null {
    return this._ngControl.statusChanges;
  }

  public get ngControl(): NgControl {
    return this._ngControl;
  }
}
